import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import SendNotificationForm from "../../../components/form/SendNotificationForm";
import { sendNotification } from "../../../api/notification";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SendCustomNotification = () => {
  // state
  const [errorMessage, setErrorMessage] = useState("");

  // objects
  const navigate = useNavigate();

  // functions

  const submitSendNotification = (values) => {
    console.log("Values: ", values);

    //notify
    let notify = {};
    if (values.notify !== "individualUser") {
      notify = {
        [values.notify]: true,
      };
    }

    let selectedUsers = [];

    values.users.map((user) => selectedUsers.push(user.id));

    const data = {
      message: values.message,
      notificationType: values.notificationType,
      notificationTypeId: 0,
      title: values.title,
      userIds: selectedUsers,
      ...notify,
    };

    console.log("sending data: ", data);
    // return;

    sendNotification(data)
      .then((response) => {
        console.log("Notification Sended!");
        console.log("notification send response", response);
        return navigate("/notification/all");
      })
      .catch((error) => {
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later."
        );
      });
  };

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Helmet title="Send Notification" />
      <Typography variant="h3" gutterBottom display="inline">
        Create Notification
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>

        <Link component={NavLink} to="/notification/all">
          Notifications List
        </Link>

        <Typography>Create Notification</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"} alignItems="center">
        <Grid item xs={10} md={6}>
          <Card p={8}>
            <SendNotificationForm
              errorMessage={errorMessage}
              navigate={navigate}
              onSubmit={submitSendNotification}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SendCustomNotification;
