import { customBlue } from "./variants";

const textFieldInputProps = {
  style: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    padding: "10.5px 15px",
  },
};

const imageDefaultStyleCover = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const imageDefaultStyleContain = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};

const sxTableImageContainerStyle = {
  height: "100px",
  width: "150px",
  borderRadius: "8px",
  overflow: "hidden",
};

const sxFancyTableBorder = {
  border: "1px solid #d9dede",
  borderCollapse: "separate",
  borderRadius: "8px",
  overflow: "hidden",
};

const sxTableHeadBG = {
  background: customBlue[50],
};

const sxTableActionButtonBorder = {
  border: `1px solid ${customBlue[200]}`,
};

const sxTableActionButtonBorderRed = {
  border: `1px solid #FF8B8B`,
};

const activeIndicateColor = "#14C38E";

const inActiveIndicateColor = "#EB4747";

const myGrey = "#d9dede";

const softPink = "#FF8B8B";

const myBlack = "#000";

const blueBorder = {
  border: `1px solid ${customBlue[200]}`,
};

const greyBorder = {
  border: "1px solid #d9dede",
};

const sxBreadcrumbsStyle = {
  cursor: "pointer",
  textDecoration: "underline",
  color: customBlue[500],
};

const sxUpperDeleteIcon = {
  position: "absolute",
  height: "30px",
  width: "30px",
  top: 5,
  right: 5,
  background: "white",
  borderRadius: "100%",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  ...sxTableActionButtonBorderRed,
  transition: ".3s ease-in-out",
  "&:hover": {
    background: "white",
    transform: "scale(1.1) rotate(90deg)",
  },
};

export {
  textFieldInputProps,
  imageDefaultStyleCover,
  imageDefaultStyleContain,
  sxTableImageContainerStyle,
  sxFancyTableBorder,
  sxTableHeadBG,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  activeIndicateColor,
  inActiveIndicateColor,
  blueBorder,
  greyBorder,
  sxBreadcrumbsStyle,
  sxUpperDeleteIcon,
  myGrey,
  softPink,
  myBlack,
};
