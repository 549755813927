import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
// import { NavLink } from "react-router-dom";
import { Formik } from "formik";
// import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Box,
  Stack,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../ui/ErrorAlert";
import ImageUpload from "../ui/ImageUpload";
import { useState } from "react";
import { useEffect } from "react";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subtitle: Yup.string().required("Sub-Title is required"),
  blogType: Yup.string().required("Blog Type is required"),
});

function BlogForm(props) {
  const { blog, errorMessage } = props;

  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues =
    Object.keys(blog).length !== 0
      ? {
          image: blog.coverImage,
          title: blog.title,
          subtitle: blog.subtitle,
          description: blog.description,
          blogType: blog.blogType,
        }
      : {
          image: null,
          title: "",
          subtitle: "",
          description: "",
          blogType: "",
        };

  useEffect(() => {
    setHideDeleteBtn(blog.coverImage ? false : true);
  }, [blog.coverImage]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
        setFieldValue,
        status,
      }) => (
        <Card>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <FormControl
                  sx={{
                    display: "flex",
                    margin: "0 auto 15px",
                    justifyContent: "center",
                  }}
                >
                  <ImageUpload
                    name="image"
                    hideDeleteBtn={hideDeleteBtn}
                    landScape={true}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("image", event.target.files[0]);
                      setHideDeleteBtn(false);
                    }}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values.image
                        ? URL.createObjectURL(values.image)
                        : "/upload-placeholder.jpg"
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Blog Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="blogType"
                    label="Select Blog Type"
                    error={Boolean(touched.blogType && errors.blogType)}
                    value={values.blogType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="GENERAL">General</MenuItem>
                    <MenuItem value="NEWS">News</MenuItem>
                    <MenuItem value="RESOURCE">Resource</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  name="title"
                  label="Title"
                  size="small"
                  value={values.title}
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="subtitle"
                  label="Sub Title"
                  size="small"
                  value={values.subtitle}
                  error={Boolean(touched.subtitle && errors.subtitle)}
                  fullWidth
                  helperText={touched.subtitle && errors.subtitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="description"
                  label="Description"
                  multiline
                  size="small"
                  rows={4}
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <Box>
                  <Stack direction={"row"} gap={3} justifyContent={"end"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      mt={3}
                      onClick={() => props.navigate("/blog/all")}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default BlogForm;
