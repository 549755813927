import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  imageDefaultStyleCover,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
} from "../../theme/globalStyles";
import { customBlue } from "../../theme/variants";

export default function ProductAttributeTable(props) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">In Stock</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Box sx={{ textTransform: "capitalize" }}>
                  {row.productAttributeName.toLowerCase()}
                </Box>
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
              <TableCell align="left">{row.priceIncrement}</TableCell>
              <TableCell align="left">
                <Stack direction="row" gap={1} alignItems="center">
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      backgroundColor: row.stock
                        ? activeIndicateColor
                        : inActiveIndicateColor,
                    }}
                  ></div>
                  {row.stock ? "Yes" : "No"}
                </Stack>
              </TableCell>
              <TableCell align="left">
                <div style={{ display: "flex", gap: "5px" }}>
                  <IconButton
                    color="primary"
                    variant="outlined"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={() =>
                      navigate(`/product-attribute/edit/${row.id}`)
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ ...sxTableActionButtonBorderRed }}
                    onClick={() => props.deletePost(row.id)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
