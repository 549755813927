import React, { useRef, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";
import MapPicker from "react-google-map-picker";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Divider,
  Stack,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { getAllEvents } from "../../api/event";
import params from "../../utils/params";

const API_KEY = "AIzaSyBK78xDPJ1Lo6-5lIiAMvWX8Uf0B62f9Fo";
const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  // name: Yup.string().required("Name is required"),
  // eventType: Yup.string().required("Event Type is required"),
  // eventDateTime: Yup.date().required("Event Date Time is required").nullable(),
  // venue: Yup.string().required("Venue Name is required"),
  // numberOfSeats: Yup.number()
  //   .required("Number Of Seat is required")
  //   .positive("Number Of Seat must be bigger than 0")
  //   .integer(),
  // maxTicketPurchasedAllowed: Yup.number()
  //   .required("Maximum Ticket Purchased Allowed is required")
  //   .positive("Maximum Ticket Purchased Allowed must be bigger than 0")
  //   .integer(),
  // deadlineBooking: Yup.date()
  //   .required("Deadline of Booking is required")
  //   .nullable(),
  // description: Yup.string().required("Description is required"),
});

const EventAddressForm = (props) => {
  // state
  // const [events, setEvents] = useState([]);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [lattitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  // objects
  const { eventAddress, events, errorMessage } = props;
  console.log("Event address: ", eventAddress);

  const handleMap = async (e) => {
    console.log("Map data", e);
    setAddress(e.value.description);

    console.log("before function");
    await geocodeByPlaceId(e.value.place_id)
      .then((results) => {
        setLatitude(results[0].geometry.location.lat());

        setLongitude(results[0].geometry.location.lng());

        console.log("Lat: ", lattitude);
        console.log("Lng: ", longitude);

        let temp = e.value.description.split(" ");
        let lastIndex = temp.length - 1;
        setCountry(temp[lastIndex]);
      })
      .catch((error) => console.error(error));

    console.log("after function");
  };

  const initialValues =
    Object.keys(eventAddress).length !== 0
      ? {
          addressLine1: eventAddress.addressLine1 ?? "",
          addressLine2: eventAddress.addressLine2 ?? "",
          addressType: "EVENT",
          country: eventAddress.country ?? "",
          eventId:
            events.find((event) => event.id === eventAddress.eventId) ?? null,
          lattitude: eventAddress.lattitude ?? "",
          longitude: eventAddress.longitude ?? "",
          postalCode: eventAddress.postalCode ?? "",
          state: eventAddress.state ?? "",
          town: eventAddress.town ?? "",
          userId: 0,
        }
      : {
          addressLine1: address || "",
          addressLine2: "",
          addressType: "EVENT",
          country: country || "",
          eventId: null,
          lattitude: lattitude,
          longitude: longitude,
          postalCode: "",
          state: "",
          town: "",
          userId: 0,
        };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("values: ", values);
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    // fetchAllEvents();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Box
                  height={"150px"}
                  sx={{ ".menu": { background: "white", zIndex: 5 } }}
                >
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBK78xDPJ1Lo6-5lIiAMvWX8Uf0B62f9Fo"
                    selectProps={{
                      onChange: handleMap,
                      styles: {
                        option: (provided) => ({
                          ...provided,
                          opacity: `1 !important`,
                          zIndex: 5,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          opacity: `1 !important`,
                          zIndex: 5,
                        }),
                      },
                    }}
                  />
                  <TextField
                    disabled
                    label="Address"
                    value={address ? address : values.addressLine1}
                    fullWidth
                    size="small"
                    variant="outlined"
                    mt={4}
                  />

                  <Stack direction={{ xs: "column", md: "row" }} gap={3} mb={5}>
                    <TextField
                      disabled
                      label="Latitude"
                      value={lattitude ? lattitude : values.lattitude}
                      fullWidth
                      size="small"
                      variant="outlined"
                      mt={4}
                      mb={2}
                    />

                    <TextField
                      disabled
                      label="Longitude"
                      value={longitude ? longitude : values.longitude}
                      fullWidth
                      size="small"
                      variant="outlined"
                      mt={4}
                      mb={2}
                    />
                  </Stack>
                </Box>

                <Grid container columnSpacing={4}>
                  <input
                    type="hidden"
                    name="addressLine1"
                    value={values.addressLine1}
                  />

                  <input type="hidden" name="country" value={values.country} />

                  <input
                    type="hidden"
                    name="lattitude"
                    value={values.lattitude}
                  />

                  <input
                    type="hidden"
                    name="longitude"
                    value={values.longitude}
                  />

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onOpen={handleBlur}
                      value={values.eventId}
                      onChange={(event, value) =>
                        setFieldValue("eventId", value)
                      }
                      options={events}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Events"
                          placeholder="Events"
                          error={Boolean(touched.eventId && errors.eventId)}
                          helperText={touched.eventId && errors.eventId}
                          my={2}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="postalCode"
                      label="Postal Code"
                      value={values.postalCode}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="town"
                      label="Town"
                      value={values.town}
                      error={Boolean(touched.town && errors.town)}
                      helperText={touched.town && errors.town}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="state"
                      label="State"
                      value={values.state}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Divider />

                  <Grid item xs={12}>
                    <Stack justifyContent={"end"} textAlign={"end"}>
                      <Box textAlign="end">
                        <Button
                          variant="outlined"
                          onClick={() => props.navigate("/event/all")}
                        >
                          Go Back
                        </Button>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          ml={3}
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default EventAddressForm;
