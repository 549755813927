import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
  imageDefaultStyleCover,
} from "../../theme/globalStyles";

export default function AdminTable({ admins, deleteAdmin }) {
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell>Image</TableCell>

            <TableCell>First Name</TableCell>

            <TableCell>Middle Name</TableCell>

            <TableCell>Last Name</TableCell>

            <TableCell>Email</TableCell>

            <TableCell>Role</TableCell>

            <TableCell align="center">Verified</TableCell>

            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins?.map((admin) => (
            <TableRow
              key={admin.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <Box
                  sx={{
                    height: "80px",
                    width: "100px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={admin?.displayPhoto?.fileUrl ?? "/cov.png"}
                    alt={admin?.displayPhoto?.fileName ?? admin?.name}
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>

              <TableCell>{admin?.firstName ?? ""}</TableCell>

              <TableCell>{admin?.middleName ?? ""}</TableCell>

              <TableCell>{admin?.lastName ?? ""}</TableCell>

              <TableCell>{admin?.email ?? ""}</TableCell>

              <TableCell sx={{ textTransform: "capitalize" }}>
                {admin.roles[0]?.roleName.toLowerCase() ?? "No Role Assigned"}
              </TableCell>

              <TableCell>
                <Stack
                  direction={"row"}
                  gap={1}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      backgroundColor: admin.verified
                        ? activeIndicateColor
                        : inActiveIndicateColor,
                    }}
                  ></div>
                  {admin.verified ? "Yes" : "No"}
                </Stack>
              </TableCell>

              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  {admin?.email === "super_admin@disl.com" ? (
                    <IconButton
                      color="error"
                      disabled={true}
                      sx={{
                        ...sxTableActionButtonBorderRed,
                        cursor: "not-allowed",
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <Tooltip title="Delete Admin" arrow>
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => deleteAdmin(admin.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
