import axios from "../utils/axios";

export const getAllSponsors = (filters) => {
  return axios.get(`api/sponsors/all?${filters}`);
};

export const createSponsor = (data) => {
  return axios.post("api/sponsors/create", data);
};

export const getSponsorById = (id) => {
  return axios.get(`api/sponsors/id/${id}`);
};

export const updateSponsor = (data) => {
  return axios.put("api/sponsors/update", data);
};

export const deleteSponsor = (id) => {
  return axios.delete(`api/sponsors/delete/id/${id}`);
};
