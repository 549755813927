import axios from "../utils/axios";

// get all board-member
export const getAllBoardMember = (filters) => {
  return axios.get(`api/board-members/all?${filters}`);
};

// create a board-member
export const createBoardMember = (data) => {
  return axios.post("api/board-members/create", data);
};

// get a board-member
export const getBoardMemberById = (id) => {
  return axios.get(`api/board-members/id/${id}`);
};

// update a board-member
export const updateBoardMember = (data) => {
  return axios.put("api/board-members/update", data);
};

// delete a board-member
export const deleteBoardMember = (id) => {
  return axios.delete(`api/board-members/delete/id/${id}`);
};
