import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Stack,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { uploadFile } from "../../api/file";
import { getBlogById, createBlog, updateBlog } from "../../api/blog";
import BlogForm from "../../components/form/BlogForm";

const CreateBlog = () => {
  const params = useParams();
  const blogId = params?.id;
  const [blog, setBlog] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();

  const navigate = useNavigate();

  const showAllBlogHandler = () => {
    navigate("/blog/all");
  };

  const fetchBlogById = () => {
    if (blogId) {
      getBlogById(blogId)
        .then((response) => {
          console.log("blog response", response);
          const responseBlog = response.data.payload;
          setBlog(responseBlog);
        })
        .catch((error) => console.log("blog fetch error", error));
    }
  };

  const submitBlog = (values) => {
    console.log("values: ", values);

    if (values.image && !values.image.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.image);
      formData.append("fileType ", "BLOG_IMAGE");
      formData.append("fileUploadType", "BLOG_IMAGE");

      uploadFile(formData)
        .then((response) => {
          console.log("image upload response: ", response);

          let data = blogId
            ? JSON.stringify({
                ...values,
                dbFileId: response.data.payload.id,
                userAuthId: user.id,
                uuid: blog.uuid,
              })
            : JSON.stringify({
                ...values,
                dbFileId: response.data.payload.id,
                userAuthId: user.id,
              });

          if (blogId) {
            updateBlog(data)
              .then((response) => {
                console.log("Blog updated successfully", response);
                return navigate("/blog/all");
              })
              .catch((error) => {
                setErrorMessage(
                  error.response.data.message ||
                    "Something went wrong, please try again later"
                );
              });
          } else {
            createBlog(data)
              .then((response) => {
                console.log("Blog Created successfully");
                console.log("blog create response", response);
                return navigate("/blog/all");
              })
              .catch((error) => {
                setErrorMessage(
                  error.response.data.message ||
                    "Something went wrong, please try again later"
                );
              });
          }
        })
        .catch((error) => {});
    } else {
      let data = blogId
        ? JSON.stringify({
            ...values,
            dbFileId: blog?.coverImage?.id,
            userAuthId: user.id,
            uuid: blog.uuid,
          })
        : JSON.stringify({
            ...values,
            dbFileId: blog?.coverImage?.id,
            userAuthId: user.id,
          });

      if (blogId) {
        updateBlog(data)
          .then((response) => {
            console.log("Blog updated successfully", response);
            return navigate("/blog/all");
          })
          .catch((error) => {
            setErrorMessage(
              error.response.data.message ||
                "Something went wrong, please try again later"
            );
          });
      } else {
        createBlog(data)
          .then((response) => {
            console.log("Blog Created successfully");
            console.log("blog create response", response);
            return navigate("/blog/all");
          })
          .catch((error) => {
            setErrorMessage(
              error.response.data.message ||
                "Something went wrong, please try again later"
            );
          });
      }
    }
  };

  useEffect(() => {
    fetchBlogById();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={blogId ? "Update Blog" : "Create Blog"} />
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"end"}
      >
        <Box>
          <Typography variant="h3" gutterBottom display="inline">
            {blogId ? "Update" : "Create New"} Blog
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Link component={NavLink} to="/blog/all">
              All Blog
            </Link>

            <Typography>{blogId ? "Update" : "Create"}</Typography>
          </Breadcrumbs>
        </Box>

        <Button variant="contained" onClick={showAllBlogHandler}>
          Show All Blog
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <BlogForm
                navigate={navigate}
                blog={blog}
                onSubmit={submitBlog}
                errorMessage={errorMessage}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateBlog;
