import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography, Divider, Box } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ChangePasswordComponent from "../../components/auth/ChangePassword";
import { imageDefaultStyleContain } from "../../theme/globalStyles";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ChangePassword() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper>
        <Helmet title="Change Password" />

        <Box sx={{ height: "100px", width: "80px", margin: "0 auto", mb: 3 }}>
          <img
            src="/static/img/nypd_logo.svg"
            alt="nypd-log"
            style={{ ...imageDefaultStyleContain }}
          />
        </Box>

        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom
          mb={3}
        >
          Change Password
        </Typography>

        <ChangePasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ChangePassword;
